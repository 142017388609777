// src/components/RegisterForm.jsx
import React, { useState } from 'react';
import { registerUser } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LoginForm from './LoginForm';

const RegisterForm = ({ onClose, onRegister }) => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleEmailBlur = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userData.email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError(null);
      try {
        const existingUserResponse = await fetch(`https://api-u5fnyfoy7a-uc.a.run.app/api/auth/check-email?email=${userData.email}`);
        
        if (!existingUserResponse.ok) {
          throw new Error('Failed to check email. Server responded with an error.');
        }
  
        const responseText = await existingUserResponse.text();
        try {
          const { exists } = JSON.parse(responseText);
          if (exists) {
            setEmailError('Email already exists.');
          } else {
            setEmailError(null);
          }
        } catch (jsonError) {
          console.error('Failed to parse response as JSON:', jsonError);
          console.error('Raw response:', responseText);
          setEmailError('Unexpected response from the server.');
        }
      } catch (error) {
        console.error('Error checking email:', error);
        setEmailError('Error checking email. Please try again later.');
      }
    }
  };

  const handlePasswordBlur = () => {
    if (userData.password.length < 6) {
      setPasswordError('Password must be at least 6 characters long.');
    } else {
      setPasswordError(null);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (userData.confirmPassword !== userData.password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError || passwordError || confirmPasswordError) {
      setMessage('Please fix the errors before submitting.');
      setMessageType('danger');
      return;
    }

    try {
      const response = await registerUser({
        username: userData.username,
        email: userData.email,
        password: userData.password
      });
      setMessage(response.message || 'You have registered successfully.');
      setMessageType('success');

      setTimeout(() => {
        if (typeof onRegister === 'function') {
          onRegister();  // Trigger the switch to the login form
        }
      }, 2000);
    } catch (error) {
      setMessage(error.message || 'Registration error. Please try again later.');
      setMessageType('danger');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleForm = () => {
    onRegister();
  };

  return (
    <div className="form-container">
      <>
        <h3>Register</h3>
        {message && <div className={`alert alert-${messageType}`}>{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="register-username">Username</label>
            <input
              type="text"
              className="form-control"
              id="register-username"
              name="username"
              value={userData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="register-email">Email</label>
            <input
              type="email"
              className="form-control"
              id="register-email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              onBlur={handleEmailBlur}
              required
            />
            {emailError && <div className="text-danger">{emailError}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="register-password">Password</label>
            <div className="password-input">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="register-password"
                name="password"
                value={userData.password}
                onChange={handleChange}
                onBlur={handlePasswordBlur}
                required
              />
              <span className="password-toggle" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {passwordError && <div className="text-danger">{passwordError}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <div className="password-input">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="confirm-password"
                name="confirmPassword"
                value={userData.confirmPassword}
                onChange={handleChange}
                onBlur={handleConfirmPasswordBlur}
                required
              />
              <span className="password-toggle" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
          </div>
          <button type="submit" className="btn btn-outline-warning">Register</button>
        </form>
        <div className="form-footer mt-4">
            <p>Already have an account? <br /> <span className="link" onClick={handleToggleForm}>Log in here</span></p>
          </div>
      </>
    </div>
  );
};

export default RegisterForm;






