// src/services/api.js

import axios from 'axios';
import { getAuthToken, setAuthToken } from './auth';
import { ref, deleteObject } from 'firebase/storage';
import { storage as storageConfig } from '../firebase-config';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/api'
    : 'https://api-u5fnyfoy7a-uc.a.run.app/api'; // Firebase URL for production

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

// Intercept request to include the token
apiInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Helper function to handle API errors
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
    throw error.response.data;
  } else {
    console.error('API Error:', error.message);
    throw new Error('An error occurred while processing your request.');
  }
};

// Function to register a new user
export const registerUser = async (userData) => {
  try {
    const response = await apiInstance.post('/auth/register', userData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to log in a user
export const loginUser = async (identifier, password) => {
  try {
    console.log(`Attempting login with identifier: ${identifier} and password: ${password}`);
    const response = await apiInstance.post('/auth/login', { identifier, password });
    const { token } = response.data;
    setAuthToken(token);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch user data
export const fetchUserData = async () => {
  const token = getAuthToken();
  if (!token) return null;
  setAuthToken(token);
  try {
    const response = await apiInstance.get('/auth/me');
    return response.data;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

// Function to log out a user
export const logoutUser = async () => {
  try {
    sessionStorage.clear();  // Clear session storage when logging out
    await apiInstance.post('/auth/logout');
    setAuthToken(null);
    return true;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch etchings data
export const fetchEtchings = async () => {
  try {
    const response = await apiInstance.get('/etchings');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch paintings data
export const fetchPaintings = async () => {
  try {
    const response = await apiInstance.get('/paintings');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch favorite count
export const fetchFavoriteCount = async () => {
  try {
    const response = await apiInstance.get('/favorites/count');
    return response.data.count;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch favorites data
export const fetchFavorites = async () => {
  try {
    const response = await apiInstance.get('/favorites');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to add an item to favorites
export const addToFavorites = async (itemId, itemType) => {
  try {
    const response = await apiInstance.post('/favorites', { itemId, itemType });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to remove an item from favorites
export const removeFromFavorites = async (itemId, itemType) => {
  try {
    const response = await apiInstance.delete(`/favorites/${itemId}`, { data: { itemType } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch basket count
export const fetchBasketCount = async () => {
  try {
    const response = await apiInstance.get('/basket/count');
    return response.data.count;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch basket data
export const fetchBasket = async () => {
  try {
    const response = await apiInstance.get('/basket');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to add an item to basket
export const addToBasket = async (itemId, itemType) => {
  try {
    const response = await apiInstance.post('/basket', { itemId, itemType });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to remove an item from basket
export const removeFromBasket = async (itemId, itemType) => {
  try {
    const response = await apiInstance.delete(`/basket/${itemId}`, { data: { itemType } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to delete image from Firebase
const deleteImageFromFirebase = async (imageUrl) => {
  try {
    const imageRef = ref(storageConfig, imageUrl);
    await deleteObject(imageRef);
  } catch (error) {
    console.error('Error deleting image from Firebase:', error);
  }
};

// Function to delete an artwork (Admin only)
export const deleteArtwork = async (id, type) => {
  try {
    console.log(`Deleting artwork with ID: ${id} and type: ${type}`);

    // Construct the endpoint based on type
    const endpoint = type === 'paintings' ? `/paintings/${id}` : `/etchings/${id}`;

    // Delete the artwork from MongoDB first
    const response = await apiInstance.delete(endpoint);

    // If deletion was successful, delete the image from Firebase storage
    if (response.status === 200 && response.data.imageUrl) {
      await deleteImageFromFirebase(response.data.imageUrl);
    }

    return response.data;
  } catch (error) {
    handleApiError(error);
    return { error: error.message || 'Error deleting artwork' };
  }
};

// Function to fetch artworks based on search query
export const fetchArtworks = async (query) => {
  try {
    const response = await apiInstance.get(`/search?q=${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching artworks:', error.response?.data || error.message);
    throw error;
  }
};

// Function to send contact message
export const sendContactMessage = async (messageData) => {
  try {
    const response = await apiInstance.post('/contacts', messageData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to send an order
export const sendOrder = async (orderDetails) => {
  try {
    const response = await apiInstance.post('/orders', orderDetails);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch users (Admin only)
export const fetchUsers = async () => {
  try {
    const response = await apiInstance.get('/admin/users');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to delete a user (Admin only)
export const deleteUser = async (id) => {
  try {
    const response = await apiInstance.delete(`/admin/users/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to update an artwork (Admin only)
export const updateArtwork = async (id, artworkData, type) => {
  try {
    const response = await apiInstance.put(`/admin/${type}/${id}`, artworkData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to toggle the availability of an artwork (Admin only)
export const toggleAvailability = async (artworkId, type) => {
  const endpoint = type === 'etchings' 
    ? `/etchings/${artworkId}/toggle-availability`
    : `/paintings/${artworkId}/toggle-availability`;

  try {
    const response = await apiInstance.patch(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error toggling availability:', error);
    throw error;
  }
};

export default apiInstance;