// src/components/Footer.jsx

// src/components/Footer.jsx

import React, { useEffect, useState } from 'react';
import './Footer.css';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage as storageConfig } from '../firebase-config';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [facebookIconUrl, setFacebookIconUrl] = useState('');
  const [instagramIconUrl, setInstagramIconUrl] = useState('');
  const [wikipediaIconUrl, setWikipediaIconUrl] = useState('');

  const fetchIcon = async (path, setUrl) => {
    try {
      const iconRef = ref(storageConfig, path);
      const url = await getDownloadURL(iconRef);
      setUrl(url);
    } catch (error) {
      console.error(`Error fetching icon from ${path}:`, error);
    }
  };

  useEffect(() => {
    fetchIcon('Balenok.com/Footer Icons/facebook.png', setFacebookIconUrl);
    fetchIcon('Balenok.com/Footer Icons/instagram.png', setInstagramIconUrl);
    fetchIcon('Balenok.com/Footer Icons/wikipedia.png', setWikipediaIconUrl);
  }, []);

  return (
    <footer className="footer">
      <div className="container text-center">
        <p className="mb-0">
          &copy; 2024 balenok.com. All rights reserved <br />
          <Link to="/privacy" className="footer-link">Imprint</Link> | 
          <Link to="/terms" className="footer-link">Terms of Service</Link>
        </p>
        <div className="social-icons">
          {facebookIconUrl && (
            <a href="https://www.facebook.com/sergei.balenok/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={facebookIconUrl} alt="Facebook" className="icon" />
            </a>
          )}
          {instagramIconUrl && (
            <a href="https://www.instagram.com/sergei_balenok/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={instagramIconUrl} alt="Instagram" className="icon" />
            </a>
          )}
          {wikipediaIconUrl && (
            <a href="https://en.wikipedia.org/wiki/Sergei_Balenok" target="_blank" rel="noopener noreferrer" className="social-icon">
              <img src={wikipediaIconUrl} alt="Wikipedia" className="icon" />
            </a>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;







