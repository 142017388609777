// src/components/LoginForm.jsx

import React, { useState } from 'react';
import { loginUser } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import RegisterForm from './RegisterForm';

const LoginForm = ({ onClose, onLogin }) => {
  const [userData, setUserData] = useState({
    identifier: '',
    password: '',
  });
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(userData.identifier, userData.password);
      if (response) {
        localStorage.setItem('authToken', response.token);
        console.log("Login successful, user data:", response);

        // Update the app state immediately with the logged-in user data
        if (typeof onLogin === 'function') {
          onLogin(response);
        }

        setMessageType('success');
        setMessage('Login successful');

        // Immediately close the login form
        onClose();

        // Delay redirection slightly to ensure UI updates correctly
        setTimeout(() => {
          if (response.isAdmin) {
            navigate('/admin'); // Redirect admin to admin panel
          } else {
            navigate('/'); // Redirect regular user to homepage
          }
        }, 100); // Short delay before redirection

      } else {
        setMessageType('danger');
        setMessage('Invalid username/email or password.');
      }
    } catch (error) {
      setMessageType('danger');
      setMessage(error.message || 'An error occurred during login.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleForm = () => {
    setShowRegisterForm(!showRegisterForm);
  };

  return (
    <div className="form-container">
      {showRegisterForm ? (
        <RegisterForm onClose={onClose} onRegister={() => setShowRegisterForm(false)} />
      ) : (
        <>
          <h3>Login</h3>
          {message && <div className={`alert alert-${messageType}`}>{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="login-identifier">Username or Email</label>
              <input
                type="text"
                className="form-control"
                id="login-identifier"
                name="identifier"
                value={userData.identifier}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group password-container">
              <label htmlFor="login-password">Password</label>
              <div className="password-input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  id="login-password"
                  name="password"
                  value={userData.password}
                  onChange={handleChange}
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
            </div>
            <button type="submit" className="btn btn-outline-warning">Login</button>
          </form>
          <div className="form-footer mt-4">
            <p>Don't have an account? <br /> <span className="link" onClick={handleToggleForm}>Register here</span></p>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginForm;



















