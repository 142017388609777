// src/App.js

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { setAuthToken, isLoggedIn as checkIsLoggedIn } from './services/auth';
import { fetchUserData, logoutUser } from './services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import FullPagePopup from './components/FullPagePopup';
import ContactForm from './components/ContactForm';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';

const Home = lazy(() => import('./components/Home'));
const Gallery = lazy(() => import('./components/Gallery'));
const Basket = lazy(() => import('./components/Basket'));
const Favorites = lazy(() => import('./components/Favorites'));
const Search = lazy(() => import('./components/Search'));
const ArtworksList = lazy(() => import('./components/ArtworksList'));
const AdminPanel = lazy(() => import('./components/AdminPanel'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));

const ProtectedRoute = ({ children, isAdmin }) => {
  const location = useLocation();

  if (!isAdmin && location.pathname === '/admin') {
    return <Navigate to="/" replace />;
  }

  return children;
};

const PopupPage = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return <FullPagePopup isVisible={true} activeTab={tab} handleClose={() => window.close()} />;
};

const App = () => {
  const [activePopup, setActivePopup] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(checkIsLoggedIn());
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const userData = await fetchUserData();
        if (userData) {
          setIsUserLoggedIn(true);
          setUsername(userData.username);
          setIsAdmin(userData.isAdmin);
          setUser(userData);
          localStorage.setItem('username', userData.username);
          localStorage.setItem('isAdmin', userData.isAdmin);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isUserLoggedIn) {
      initializeUser();
    }
  }, [isUserLoggedIn]);

  const handleTogglePopup = (type) => {
    setActivePopup(type);
  };

  const handleClosePopup = () => {
    setActivePopup(null);
  };

  const handleLogin = (userData) => {
    setIsUserLoggedIn(true);
    setUsername(userData.username);
    setIsAdmin(userData.isAdmin);
    setUser(userData);
    localStorage.setItem('authToken', userData.token);
    localStorage.setItem('username', userData.username);
    localStorage.setItem('isAdmin', userData.isAdmin);
    setAuthToken(userData.token);
    handleClosePopup();
  };

  const handleLogout = () => {
    logoutUser();
    localStorage.removeItem('authToken');
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    setIsUserLoggedIn(false);
    setIsAdmin(false);
    setUsername('');
    setUser(null);
    setAuthToken(null);
  };

  return (
    <Router>
      <div className="main-container">
        <Routes>
          <Route path="/popup" element={<PopupPage />} />
          <Route
            path="*"
            element={
              <>
                <Header
                  onTogglePopup={handleTogglePopup}
                  isLoggedIn={isUserLoggedIn}
                  isAdmin={isAdmin}
                  username={username}
                  onLogout={handleLogout}
                />
                <main className="container mt-4 flex-grow-1">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      <Route path="/" element={<Home isAdmin={isAdmin} />} />
                      <Route path="/gallery" element={<Gallery />} />
                      <Route path="/etchings" element={<ArtworksList type="etchings" handleTogglePopup={handleTogglePopup} />} />
                      <Route path="/paintings" element={<ArtworksList type="paintings" handleTogglePopup={handleTogglePopup} />} />
                      <Route path="/basket" element={<Basket />} />
                      <Route path="/privacy" element={<PrivacyPolicy />} />
                      <Route path="/terms" element={<TermsOfService />} />
                      <Route path="/favorites" element={<Favorites />} />
                      <Route path="/contact" element={<ContactForm user={user} />} />
                      <Route path="/search" element={<Search handleTogglePopup={handleTogglePopup} />} />
                      <Route path="/admin" element={
                        <ProtectedRoute isAdmin={isAdmin}>
                          <AdminPanel />
                        </ProtectedRoute>
                      } />
                      <Route path="/login" element={<LoginForm onClose={handleClosePopup} onLogin={handleLogin} />} />
                      <Route path="/register" element={<RegisterForm onClose={handleClosePopup} />} />
                    </Routes>
                  </Suspense>
                </main>
                <Footer />
                {activePopup && ['login', 'register', 'contact'].includes(activePopup) && (
                  <FullPagePopup
                    isVisible={true}
                    handleClose={handleClosePopup}
                    activeTab={activePopup}
                    onLogin={handleLogin}
                    user={user}
                  />
                )}
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;


