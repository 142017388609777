// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TermsOfService.css*/

.terms-of-service-container {
  padding: 2rem;
  margin: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-weight: 300;
}
  
.terms-of-service-container h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1rem;
}
  
.terms-of-service-container h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-top: 1.1rem;
}
  
.terms-of-service-container p {
  font-size: 14px;
  color: #666;
}
  
.terms-of-service-container ul {
  padding-left: 1.5rem;
}
  
.terms-of-service-container ul li {
  list-style-type: disc;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {

  .terms-of-service-container h3 {
    font-size: 1.4rem; 
  }
  
  .terms-of-service-container h5 {
    font-size: 1.2rem; 
  }

  .terms-of-service-container p {
    font-size: 15px;
  }

}

@media (min-width: 992px) {

  .terms-of-service-container h3 {
    font-size: 1.4rem;
  }
  
  .terms-of-service-container h5 {
    font-size: 1.2rem; 
  }

  .terms-of-service-container p {
    font-size: 15px;
  }
  
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsOfService.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAErC;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;;EAEE;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;AAEF;;AAEA;;EAEE;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;AAEF","sourcesContent":["/* src/components/TermsOfService.css*/\r\n\r\n.terms-of-service-container {\r\n  padding: 2rem;\r\n  margin: 20px;\r\n  background-color: #fff;\r\n  border-radius: 8px;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n  font-weight: 300;\r\n}\r\n  \r\n.terms-of-service-container h3 {\r\n  font-family: 'Roboto', sans-serif;\r\n  font-weight: 400;\r\n  text-align: center;\r\n  margin-bottom: 1rem;\r\n}\r\n  \r\n.terms-of-service-container h5 {\r\n  font-family: 'Roboto', sans-serif;\r\n  font-weight: 400;\r\n  margin-top: 1.1rem;\r\n}\r\n  \r\n.terms-of-service-container p {\r\n  font-size: 14px;\r\n  color: #666;\r\n}\r\n  \r\n.terms-of-service-container ul {\r\n  padding-left: 1.5rem;\r\n}\r\n  \r\n.terms-of-service-container ul li {\r\n  list-style-type: disc;\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n@media (min-width: 768px) {\r\n\r\n  .terms-of-service-container h3 {\r\n    font-size: 1.4rem; \r\n  }\r\n  \r\n  .terms-of-service-container h5 {\r\n    font-size: 1.2rem; \r\n  }\r\n\r\n  .terms-of-service-container p {\r\n    font-size: 15px;\r\n  }\r\n\r\n}\r\n\r\n@media (min-width: 992px) {\r\n\r\n  .terms-of-service-container h3 {\r\n    font-size: 1.4rem;\r\n  }\r\n  \r\n  .terms-of-service-container h5 {\r\n    font-size: 1.2rem; \r\n  }\r\n\r\n  .terms-of-service-container p {\r\n    font-size: 15px;\r\n  }\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
