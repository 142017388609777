// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* public/index.css */

html, body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

body, html, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  background-image: 
    linear-gradient(90deg, rgba(255, 255, 255, 0.01) 50%, transparent 50%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.01) 50%, transparent 50%),
    linear-gradient(90deg, rgba(0, 0, 0, 0.01) 50%, transparent 50%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.01) 50%, transparent 50%);
  background-size: 2px 2px, 2px 2px, 1px 1px, 1px 1px;
  background-blend-mode: overlay, overlay, multiply, multiply;
}

.popup-background {
  background-position: right center;
}

.modal-details {
  transition: all 0.3s ease;
  column-gap: 16px;
}

input:placeholder-shown {
  color: #aaa;
}












`, "",{"version":3,"sources":["webpack://./public/index.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,kBAAkB;EAClB,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,OAAO;EACP,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB;;;;mEAIiE;EACjE,mDAAmD;EACnD,2DAA2D;AAC7D;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* public/index.css */\r\n\r\nhtml, body {\r\n  overflow-x: hidden;\r\n  font-family: 'Roboto', sans-serif;\r\n  font-weight: 300;\r\n}\r\n\r\nbody, html, #root {\r\n  height: 100%;\r\n  margin: 0;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.main-container {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: #f7f7f7;\r\n  background-image: \r\n    linear-gradient(90deg, rgba(255, 255, 255, 0.01) 50%, transparent 50%),\r\n    linear-gradient(0deg, rgba(255, 255, 255, 0.01) 50%, transparent 50%),\r\n    linear-gradient(90deg, rgba(0, 0, 0, 0.01) 50%, transparent 50%),\r\n    linear-gradient(0deg, rgba(0, 0, 0, 0.01) 50%, transparent 50%);\r\n  background-size: 2px 2px, 2px 2px, 1px 1px, 1px 1px;\r\n  background-blend-mode: overlay, overlay, multiply, multiply;\r\n}\r\n\r\n.popup-background {\r\n  background-position: right center;\r\n}\r\n\r\n.modal-details {\r\n  transition: all 0.3s ease;\r\n  column-gap: 16px;\r\n}\r\n\r\ninput:placeholder-shown {\r\n  color: #aaa;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
