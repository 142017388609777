// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/LoginForm.css */

.form-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.form-container h3 {
  font-weight: 400;
  font-size:1.4rem;
  margin-bottom: 25px;
}

.form-group label {
  margin-bottom: 0;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #FDE29C;
  outline: none;
}

.password-input {
  position: relative;
  width: 100%;
}

.password-input input {
  padding-right: 30px;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.form-footer {
  width: 100%;
  text-align: center;
}

.form-footer .link {
  color:  #FAC53A;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
}

.form-footer .link:hover {
  color: black;
}

`, "",{"version":3,"sources":["webpack://./src/components/LoginForm.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;EACE,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;;EAEE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* src/components/LoginForm.css */\r\n\r\n.form-container {\r\n  width: 100%;\r\n  max-width: 500px;\r\n  margin: 0 auto;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.form-container h3 {\r\n  font-weight: 400;\r\n  font-size:1.4rem;\r\n  margin-bottom: 25px;\r\n}\r\n\r\n.form-group label {\r\n  margin-bottom: 0;\r\n}\r\n\r\n.form-group input {\r\n  width: 100%;\r\n  padding: 0.75rem;\r\n  border-radius: 4px;\r\n  border: 1px solid #ccc;\r\n}\r\n\r\n.form-group input:focus,\r\n.form-group textarea:focus {\r\n  border-color: #FDE29C;\r\n  outline: none;\r\n}\r\n\r\n.password-input {\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.password-input input {\r\n  padding-right: 30px;\r\n}\r\n\r\n.password-toggle {\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  cursor: pointer;\r\n}\r\n\r\n.form-footer {\r\n  width: 100%;\r\n  text-align: center;\r\n}\r\n\r\n.form-footer .link {\r\n  color:  #FAC53A;\r\n  font-weight: 400;\r\n  cursor: pointer;\r\n  text-decoration: underline;\r\n}\r\n\r\n.form-footer .link:hover {\r\n  color: black;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
