// src/services/auth.js

import axios from 'axios';

const BASE_URL = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_API_BASE_URL // Local for development
  : process.env.REACT_APP_FUNCTIONS_API_URL; // Firebase Functions URL for production

const api = axios.create({
  baseURL: BASE_URL,
});

// Function to set authentication token for API requests
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('authToken', token);
  } else {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('authToken');
  }
};

// Function to get authentication token
export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// Function to check if user is logged in
export const isLoggedIn = () => {
  return !!localStorage.getItem('authToken');
};

// Function to check if user is an admin
export const isAdmin = async () => {
  const token = getAuthToken();
  if (!token) return false;
  try {
    const response = await api.get('/auth/me');
    return response.data.isAdmin; // Ensure this returns correct admin status
  } catch (error) {
    handleApiError(error);
    return false;
  }
};

// Intercept request to include the token
api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Function to log in a user
export const login = async (identifier, password) => {
  try {
    const response = await api.post('/auth/login', { identifier, password });
    const { token } = response.data;
    setAuthToken(token);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};


// Function to fetch user data
export const fetchUserData = async () => {
  const token = getAuthToken();
  if (!token) return null;
  setAuthToken(token);
  try {
    const response = await api.get('/auth/me');
    return response.data;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

// Helper function to handle API errors
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
    throw error.response.data;
  } else {
    console.error('API Error:', error.message);
    throw new Error('An error occurred while processing your request.');
  }
};
 