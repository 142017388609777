// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Footer.css */

.footer {
  padding: 10px;
  font-size: 14px;
}

.footer-link {
  text-decoration: none;
  color: #666;
}

.footer-link:focus, .footer-link:active {
  color: #E3B620;
}

.social-icon {
  margin: 0 2px;
  transition: filter 0.3s ease;
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.social-icon:hover .icon {
  filter: grayscale(0%);
}

@media (min-width: 768px) {

  .footer {
    font-size: 16px;
  }
  
  .icon {
    width: 1.9rem; 
    height: 1.9rem;
  }

}

@media (min-width: 1020px) {
  
  .icon {
    width: 2rem;
    height: 2rem;
  }

}




  `, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;AAEF;;AAEA;;EAEE;IACE,WAAW;IACX,YAAY;EACd;;AAEF","sourcesContent":["/* src/components/Footer.css */\r\n\r\n.footer {\r\n  padding: 10px;\r\n  font-size: 14px;\r\n}\r\n\r\n.footer-link {\r\n  text-decoration: none;\r\n  color: #666;\r\n}\r\n\r\n.footer-link:focus, .footer-link:active {\r\n  color: #E3B620;\r\n}\r\n\r\n.social-icon {\r\n  margin: 0 2px;\r\n  transition: filter 0.3s ease;\r\n}\r\n\r\n.icon {\r\n  width: 1.8rem;\r\n  height: 1.8rem;\r\n  filter: grayscale(100%);\r\n  transition: filter 0.3s ease;\r\n}\r\n\r\n.social-icon:hover .icon {\r\n  filter: grayscale(0%);\r\n}\r\n\r\n@media (min-width: 768px) {\r\n\r\n  .footer {\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .icon {\r\n    width: 1.9rem; \r\n    height: 1.9rem;\r\n  }\r\n\r\n}\r\n\r\n@media (min-width: 1020px) {\r\n  \r\n  .icon {\r\n    width: 2rem;\r\n    height: 2rem;\r\n  }\r\n\r\n}\r\n\r\n\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
