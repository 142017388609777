// src/firebase-config.js


import { initializeApp, getApps, getApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZZ3LV7E9wVTFzNWPrWK2Zu4G9y-r-1WI",
  authDomain: "artist-website-f082c.firebaseapp.com",
  projectId: "artist-website-f082c",
  storageBucket: "artist-website-f082c.appspot.com",
  messagingSenderId: "380060932317",
  appId: "1:380060932317:web:90ab791cd11b0eec7ea334",
  measurementId: "G-GVJVG3Y2LV"
};

// Initialize Firebase only if it hasn't been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Storage
const storage = getStorage(app);


console.log('Firebase storage initialized:', storage);

export { storage };
