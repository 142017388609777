// src/components/TermsOfService.jsx

import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h3>Terms of Service</h3>
      <p>Effective Date: July 14, 2024</p>
      
      <section>
        <h5>1. Acceptance of Terms</h5>
        <p>By accessing and using our website, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using our services, you shall be subject to any posted guidelines or rules applicable to such services.</p>
      </section>

      <section>
        <h5>2. Provision of Services</h5>
        <p>Balenok.com is providing the services to you subject to the following terms and conditions. We reserve the right to update the terms at any time without notice to you. The most current version of the terms can be reviewed by clicking on the "Terms of Service" link located at the bottom of our pages.</p>
      </section>

      <section>
        <h5>3. User Responsibilities</h5>
        <p>As a condition of use, you promise not to use the services for any purpose that is unlawful or prohibited by these terms, or any other purpose not reasonably intended by Balenok.com.</p>
      </section>

      <section>
        <h5>4. Account Terms</h5>
        <p>You are responsible for maintaining the security of your account and password. Balenok.com cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</p>
      </section>

      <section>
        <h5>5. Termination</h5>
        <p>We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.</p>
      </section>

      <section>
        <h5>6. Governing Law</h5>
        <p>These terms shall be governed and construed in accordance with the laws of the country, without regard to its conflict of law provisions.</p>
      </section>

      <section>
        <h5>7. Changes to Terms</h5>
        <p>We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect.</p>
      </section>

      <section>
        <h5>8. Contact Us</h5>
        <p>If you have any questions about these terms, please contact us via contact form .</p>
      </section>
    </div>
  );
};

export default TermsOfService;


